<template>
  <div @click = "toggleVisibility" style="position: relative; cursor: pointer;" >
    <TooltipIcon />
    <span v-if="isVisible" class="tooltip" v-html="tooltipText"></span>
  </div>
</template>
<script>
import TooltipIcon from '@/components/icons/TooltipIcon.vue';
export default {
  props: {
    tooltipText: {
      type: String
    }
  },
  components: {
    TooltipIcon
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    }
  },
}
</script>

<style scoped>
.tooltip {
  width: 320px;
  bottom: 110%;
  left: -160px;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  border-radius: 6px;
  opacity: 0.9;
  background: var(--Neutral-Grey-300, #222325);
  z-index: 1;
  /* Shadow */
  box-shadow: 0px var(--XS, 8px) var(--S, 16px) 0px rgba(12, 16, 13, 0.40), 0px 0px 1px 0px rgba(12, 16, 13, 0.20);

}
</style>