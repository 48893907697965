<template>
  <b-button class="btn-secondary" :disabled="disabled" @click="onClick" :class="{ 'inactive': disabled }">
    <div class="svg">
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon Item">
          <path id="Path"
            d="M11.0015 17.4976L11.0015 4.65409L16.858 10.5105L17.9342 9.43423L10.25 1.75L2.56201 9.43799L3.63452 10.5105L9.49094 4.65409L9.49472 17.4939L11.0015 17.4976Z"
            fill="#E6E1F3" />
        </g>
      </svg>

    </div>
    <div class="text"> {{ buttonText }}</div>

  </b-button>
</template>

<script>
export default {
  name: 'DownButton',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
  }
}
</script>
<style scoped>
button {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.inactive {
  opacity: 0.4 !important; 
}
.disabled {
  padding: var(--margin-s) var(--margin-l) !important;
  background-color: var(--color-gray-200);
  opacity: 0.4 !important;
  border: none;
}
</style>