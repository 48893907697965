<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="info_FILL0_wght400_GRAD0_opsz48">
<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M9 18C7.725 18 6.54 17.7712 5.445 17.3137C4.35 16.8562 3.3975 16.2225 2.5875 15.4125C1.7775 14.6025 1.14375 13.65 0.68625 12.555C0.22875 11.46 0 10.275 0 9C0 7.74 0.22875 6.5625 0.68625 5.4675C1.14375 4.3725 1.7775 3.42 2.5875 2.61C3.3975 1.8 4.35 1.1625 5.445 0.6975C6.54 0.2325 7.725 0 9 0C10.26 0 11.4375 0.2325 12.5325 0.6975C13.6275 1.1625 14.58 1.8 15.39 2.61C16.2 3.42 16.8375 4.3725 17.3025 5.4675C17.7675 6.5625 18 7.74 18 9C18 10.275 17.7675 11.46 17.3025 12.555C16.8375 13.65 16.2 14.6025 15.39 15.4125C14.58 16.2225 13.6275 16.8562 12.5325 17.3137C11.4375 17.7712 10.26 18 9 18ZM9.52875 6.2325C9.38625 6.3675 9.21 6.435 9 6.435C8.79 6.435 8.61375 6.3675 8.47125 6.2325C8.32875 6.0975 8.2575 5.925 8.2575 5.715C8.2575 5.505 8.32875 5.325 8.47125 5.175C8.61375 5.025 8.79 4.95 9 4.95C9.21 4.95 9.38625 5.025 9.52875 5.175C9.67125 5.325 9.7425 5.505 9.7425 5.715C9.7425 5.925 9.67125 6.0975 9.52875 6.2325ZM9.7425 13.5H8.3925V8.1H9.7425V13.5ZM14.4 14.4C12.9 15.9 11.1 16.65 9 16.65C6.9 16.65 5.1 15.9 3.6 14.4C2.1 12.9 1.35 11.1 1.35 9C1.35 6.9 2.1 5.1 3.6 3.6C5.1 2.1 6.9 1.35 9 1.35C11.1 1.35 12.9 2.1 14.4 3.6C15.9 5.1 16.65 6.9 16.65 9C16.65 11.1 15.9 12.9 14.4 14.4Z" fill="#0CBA4A"/>
</g>
</svg>




</template>

<script>
export default {
  name: 'TooltipIcon',
}
</script>

<style lang="scss" scoped></style>