<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.83358L4.74731 10.1669L12.2131 1.35742" stroke="#0CBA4A" stroke-width="2.1" />
  </svg>
  


</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>