<template>
  <div class="inventory-head">
    <div class="d-flex align-center gap-s">
      <FrontendFilterSearch :placeholder="$t('inventory.searchProduct')" />
      <b-button-group class="toggleButtons wineViewFilter">
        <b-button :class="IsThisViewActive('uncheckedWines')" @click="toggleViewFromHead('uncheckedWines', 'inventory.unchecked')">{{ $t('inventory.unchecked')}}</b-button>
        <b-button :class="IsThisViewActive('checkedWines')" @click="toggleViewFromHead('checkedWines', 'inventory.checked')">{{ $t('inventory.checked')}}</b-button>
      </b-button-group> 
    </div>
    <div class="gap-s d-flex">
      <button :disabled="inventoryFinished" @click="addWine()" class="iconButton add">{{ $t('inventory.addProduct')}}</button>
      <Button v-if="isInventoryOnly" class="w-m ml-m" :onClick="toggleSidepanelFromHead" :buttonText="inventoryModeText"
      :primary="true">
      <template v-slot:svg>
        <OpenSidepanel v-if="sidepanelOpen"/>
        <CloseSidepanel v-else/>
      </template>
    </Button>
      
    </div>
  </div>

</template>

<script>
import FrontendFilterSearch from '../inputs/FrontendFilterSearch.vue';
import Button from '@/components/buttons/Button.vue';
import OpenSidepanel from '@/components/icons/OpenSidepanel.vue';
import CloseSidepanel from '@/components/icons/CloseSidepanel.vue';
import { mapGetters } from 'vuex';


export default {
  data() {
    return {
      sidepanelOpen: false
    };
  },
  components: {
    FrontendFilterSearch,
    Button,
    OpenSidepanel,
    CloseSidepanel
  },
  props: {
    progress: {
      type: Object,
    },
    inventoryFinished: {
      type: Boolean,
    },
    rowIndex: {
      type: Number,
      default: null
    },
    inventory: {
      type: Object,
    },
  },
  methods: {
    addWine() {
      this.$emit('addWine');
    },
    toggleSidepanelFromHead() {
     if (this.sidepanelOpen) {
      console.log('emit close');
       this.$emit('closeSidepanel');
     } else {
       this.$emit('openSidepanel');
       console.log('emit open');
     }
    },
    buttonTextInventoryOn() {
      this.sidepanelOpen = false;
    },
    buttonTextInventoryOff() {
      this.sidepanelOpen = true;
    },
    toggleSidepanel() {
      this.sidepanelOpen = !this.sidepanelOpen;
    },
    IsThisViewActive(view){
      if(view === this.$route.params.filterString) {
        return 'active';
      }
    },
    toggleViewFromHead(filterString, titleKey) {
      this.$router.replace({ 
        name: 'InventoryWines', 
        params: {
          id: this.inventory.id, 
          inventory: this.inventory, 
          filterString: filterString,
          titleKey: titleKey 
        } 
      }).then(() => {
        // Seite neu laden
        this.$emit('toggleView', this.$route.params.id, this.$route.params.filterString, this.$t(titleKey));
      });
    }
  },
  computed: {
    ...mapGetters(['isInventoryOnly']),
    inventoryModeText() {
      return this.sidepanelOpen ? this.$t('inventory.modeOff') : this.$t('inventory.modeOn');
    }
  }
};
</script>
<style scoped>
.inventory-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 16px;
}

div.back-button {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.inventory-head button.sidepanel-button {
  background-color: var(--color-gray-400) !important;
  border: none !important;
}
</style>