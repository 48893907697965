<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_38373_14869" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#fff" />
    </mask>
    <g mask="url(#mask0_38373_14869)">
      <path
        d="M6.25 12.3333C6.25 12.5278 6.33333 12.6597 6.5 12.7292C6.66667 12.7986 6.81944 12.7639 6.95833 12.625L9 10.5833C9.16667 10.4167 9.25 10.2222 9.25 10C9.25 9.77778 9.16667 9.58333 9 9.41667L6.95833 7.375C6.81944 7.23611 6.66667 7.20139 6.5 7.27083C6.33333 7.34028 6.25 7.47222 6.25 7.66667V12.3333ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM11.6667 15.8333V4.16667H4.16667V15.8333H11.6667Z"
        :fill="fill" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloseSidepanel',
  props: {
    width: {
      type: [String, Number],
      default: 20
    },
    height: {
      type: [String, Number],
      default: 20
    },
    fill: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style lang="scss" scoped></style>