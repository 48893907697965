<template>
  <b-button class="btn-primary" @click="onClick" :class="{ 'inactive': disabled }">
    <div class="svg">
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon Item">
          <path id="Path"
            d="M9.49848 2.50236L9.49847 15.3459L3.64205 9.48949L2.56577 10.5658L10.25 18.25L17.938 10.562L16.8655 9.48949L11.0091 15.3459L11.0053 2.50612L9.49848 2.50236Z"
            fill="white" />
        </g>
      </svg>


    </div>
    <div class="text"> {{ buttonText }}</div>

  </b-button>
</template>

<script>
export default {
  name: 'DownButton',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
  }
}
</script>
<style scoped>
button {
  display: flex;
  gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.inactive {
  opacity: 0.4 !important; 
}
</style>
